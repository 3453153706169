html,
body,
#root,
.top-navbar {
  background-color: #fff;
}

.bottom-navbar {
  background-color: #fff;
}

.footer {
  background-color: #fff;
  height: 3rem;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-logo-a {
  max-width: 100%;
}

.navbar-logo {
  width: 100%;
}
