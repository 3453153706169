.results-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  h3 {
    font-weight: 400;
  }
}
