.results-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;

  .results-card {
    width: 90%;
    height: 90%;
    background-color: #fff;
    border-radius: 5px;
    padding: 2rem;
  }

  .results-title {
    font-weight: 300;
  }
}
