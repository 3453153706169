.participant-icon {
    width: 7rem;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #6d6d6d;
    border-radius: 50%;
    font-size: 3.3rem;
    font-family: sans-serif;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }
}