.participant-info-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .participant-info-gender {
    display: flex;
    font-size: 4rem;
  }

  .participant-info-picture {
    width: 100px;
  }

  .participant-info {
    p {
      margin: 0;
    }
  }
}
