.dropdown-container {
  flex-direction: column;
  display: flex;
  width: 100%;

  @media (min-width: 520px) {
    width: 50%;
  }

  &:nth-child(2) {
    margin-top: 1rem;

    @media (min-width: 520px) {
      margin-top: unset;
      margin-left: 1rem;
    }
  }

  .dropdown-toggle {
    width: 100%;
    background-color: #fff;
    color: #000;
    border-color: #e2e2e2;

    &:focus,
    &:hover {
      background-color: #e2e2e2;
      border-color: #e2e2e2;
      color: #000;
    }
  }
}
