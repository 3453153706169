.dropdown-area-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 520px) {
    flex-direction: row;
    width: 100%;
  }
}

.results-table-spinner {
  width: 100%;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
