.timing-seperator-column {
  padding: 0.5rem;
  background-color: #e0e0e0;
  font-size: 1rem;
  font-weight: 500;
  
  @media (min-width: 520px) {
    padding: 0.75rem;
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.results-table-container {
  margin-top: 2rem;

  .search-container {    
    .search-input {
      outline: none;
      border: solid 1px #e2e2e2;
      border-radius: 5px;

      &:focus, &:focus-visible {
        border-color: #9c9c9c;
      }
    }
  }
}

.results-table {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  width: 100%;

  @media (min-width: 520px) {
    font-size: 1rem;
  }

  .results-table-row {
    border-top: #dee2e6 solid 1px;

    &:hover, &:focus {
      background-color: #f0f0f0;
    }
  }

  .results-table-cell {
    padding: 0.75rem 0;

    &--italic {
      font-style: italic;
      font-size: 0.85rem;
    }
  }
}

.results-table-options {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;

  @media (min-width: 520px) {
    flex-direction: row;
  }

  .pagination {
    margin: 1rem;

    @media (min-width: 520px) {
      margin: 0;
    }
  }

  .page-size-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page-size-label {
    padding-right: 1rem;
  }
}
