.participant-modal {
  padding: 0;

  &-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
}
